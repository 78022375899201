import { Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import 'assets/css/material-ui-custom.css';
import 'assets/css/react-toastify-custom.css';
import brands from 'brands';
import brandStyles from './../assets/styles/brands/index';
import { checkAuth } from 'features/auth/authSlice';
import { fetchLanguages } from 'features/languages/languagesSlice';
import 'normalize.css';
import PropTypes from 'prop-types';
import React, { useEffect, useContext } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import GlobalStyle from 'assets/styles/GlobalStyles';

import { BrandContext } from './brandcontext/brandContext';
import i18n from './i18n';
import { Routes } from './Routes';
import { fetchCurrentUser } from 'features/users/usersSlice';
import { useLocation } from 'react-router-dom';

const ToastClose = ({ closeToast }) => (
  <Button
    onClick={closeToast}
    style={{
      fontSize: '1.8rem',
      width: '2rem',
      color: 'rgba(255,255,255, 0.7)',
      marginTop: 8,
    }}
  >
    <MdClose />
  </Button>
);
ToastClose.propTypes = {
  closeToast: PropTypes.func,
};

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { brandName } = useContext(BrandContext);

  const languages = useSelector(state => state.languages.entities);
  const currentUser = useSelector(state => state.users.current);

  // sbb and salubox have de as default language
  const brandDefaultLanguage =
    brandName === 'salubox' || brandName === 'smartboxbasel' ? 'de' :
    brandName === 'wechip' ? 'fr' :
    'en';
  useEffect(() => {
    if (
      !location.pathname.includes('recover-password') &&
      !location.pathname.includes('register') &&
      !location.pathname.includes('terms') &&
      Object.keys(currentUser).length > 0
    ) {
      dispatch(fetchCurrentUser());
    }
    dispatch(fetchLanguages());
  }, []);

  useEffect(() => {
    // we need to call this endpoint, because inside it, we are checking if user is isAuthenticated, inside Routes.js file
    dispatch(checkAuth('You have been automatically logged out'));
    // set brand title
    document.title = `${brands[brandName].name} Portal`;

    // set brand favicon
    const link =
      document.querySelector('link[rel*="icon"]') ||
      document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = brands[brandName].favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [dispatch, brandName]);

  useEffect(() => {
    const correctLang = languages.find(
      lang => lang.id === currentUser.language___id
    );

    // //  LANG OF BROWSER
    // const browserLang = navigator.language?.slice(0, 2);
    // const languageCodes = languages
    //   ? Object.values(languages).map(lang => lang.code.toLowerCase())
    //   : [];

    i18n.changeLanguage(
      correctLang ? correctLang.code.toLowerCase() : brandDefaultLanguage

      //:
      // languageCodes.includes(browserLang)
      // ? browserLang
      // : 'en'
    );
  }, [languages, currentUser]);

  return (
    <StylesProvider injectFirst>
      <div
        style={{
          margin: '0 auto',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <GlobalStyle theme={brandStyles[brandName]} />
        {/* injectFirst prop allows styled components to inject after (override) MUI styles */}
        <ThemeProvider theme={brands[brandName].theme}>
          <CssBaseline />
          <ToastContainer
            position="bottom-right"
            closeButton={<ToastClose />}
            autoClose={10000}
          />
          <Routes />
        </ThemeProvider>
      </div>
    </StylesProvider>
  );
};

export default App;
