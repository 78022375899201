import logoLogin from './logo_login.svg';
import logoBlock from './logo.png';
import logo from './logo.png';
import Badge from './badge/zwick-web-portal-badge.png';
import Badge2x from './badge/zwick-web-portal-badge@2x.png';
import Badge3x from './badge/zwick-web-portal-badge@3x.png';

export default {
  logoLogin,
  logoBlock,
  favicon: logo,
  Badge,
  Badge2x,
  Badge3x,
};
