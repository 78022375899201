import logoLogin from './logo_login.svg';
import logoBlock from './logo_block.svg';
import logo from './logo.png';
import featureBg from './bg-line.svg';
import Badge from './badge/wechip-portal-badge1x.png';
import Badge2x from './badge/wechip-portal-badge2x.png';
import Badge3x from './badge/wechip-portal-badge3x.png';

export default {
  logoLogin,
  // logoBlock,
  featureBg,
  favicon: logo,
  Badge,
  Badge2x,
  Badge3x,
};
