import { css } from 'styled-components';
import brands from 'brands';
import 'typeface-poppins';

const { featureBg } = brands.wechip;

export default css`
  body {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    background: #142429;
    background-image: url('${featureBg}');
    background-repeat: no-repeat, no-repeat;
    background-attachment: fixed;
    background-position: right;
    background-size: contain;

    font-size: 15px;
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 890px) {
    body {
      
      background-size: cover;
    }
  }

  /* override webkit autofill styling */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #2b3e5b inset;
    -webkit-text-fill-color: #fff;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #2b3e5b inset;
    -webkit-text-fill-color: #fff;
  }
`;
