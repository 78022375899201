import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Drawer, MenuItem } from '@material-ui/core';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Container, StyledTabs, StyledTab } from './Content/Content.styled';
import { useSelector } from 'react-redux';
import { BrandContext } from 'app/brandcontext/brandContext';
import useTheme from '@material-ui/core/styles/useTheme';

const StyledMenuItem = styled(MenuItem)`
  text-transform: uppercase;
  padding: 1rem 2rem;

  ${props =>
    props.isSelected
      ? `
    background: #fff;
    color: #0E2B4D;
    &:hover {
      color: #7B7D81;
    }
  `
      : ''}
`;

export default function Menu({ isDrawerOpen, handleDrawerClose }) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [chosenTab, setChosenTab] = useState();
  const handleTabChange = (e, value) => history.push(value);
  const isWideView = useMediaQuery('(min-width: 830px)');
  const current = useSelector(state => state.auth.current);

  const { brandName } = useContext(BrandContext);
  const theme = useTheme();

  useEffect(() => {
    setChosenTab(location.pathname.replace('/', ''));
  }, [location.pathname]);

  return isWideView ? (
    <Container>
      {isWideView && (
        <StyledTabs
          value={chosenTab}
          aria-label="Section navigation"
          onChange={handleTabChange}
        >
          <StyledTab
            label={t('Locations')}
            value={`/${brandName}`}
            index={0}
            isSelected={location.pathname === `/${brandName}`}
            theme={theme}
          />
          <StyledTab
            label={t('My deliveries')}
            value={`/my-deliveries/${brandName}`}
            index={1}
            isSelected={location.pathname === `/my-deliveries/${brandName}`}
            theme={theme}
          />
          <StyledTab
            label={current.courier ? t('QR Codes') : 'Master QR code'}
            value={`/qr-codes/${brandName}`}
            index={2}
            isSelected={location.pathname === `/qr-codes/${brandName}`}
            theme={theme}
          />
        </StyledTabs>
      )}
    </Container>
  ) : (
    <Drawer
      open={isDrawerOpen}
      onClose={handleDrawerClose}
      PaperProps={{ style: { border: 'none' } }}
    >
      <div style={{ padding: '3rem 0' }}>
        <StyledMenuItem
          component={Link}
          to={`/${brandName}`}
          onClick={handleDrawerClose}
          isSelected={location.pathname === `/${brandName}`}
        >
          {t('Locations')}
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to={`/my-deliveries/${brandName}`}
          onClick={handleDrawerClose}
          isSelected={location.pathname === `/my-deliveries/${brandName}`}
        >
          {t('My deliveries')}
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to={`/qr-codes/${brandName}`}
          onClick={handleDrawerClose}
          isSelected={location.pathname === `/qr-codes/${brandName}`}
        >
          {current.courier ? t('QR Codes') : 'Master QR code'}
        </StyledMenuItem>
      </div>
    </Drawer>
  );
}
