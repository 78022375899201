import { createMuiTheme } from '@material-ui/core';
import '@fontsource/metropolis/500.css';

// TODO: define multiple themes here the user can choose from settings

const white = {
  main: '#fff',
  contrastText: '#2D2D2C',
};
const navy = {
  main: '#2D2D2C',
  contrastText: '#fff',
};

const theme = createMuiTheme({
  palette: {
    primary: white,
    secondary: navy,
    text: {
      primary: '#fff', // white.main,
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
    background: {
      paper: '#2D2D2C',
      default: '#2D2D2C', //'#878787',
    },
    type: 'dark',
  },
  typography: {
    fontFamily: [
      'Metropolis',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 16,
    fontWeight: 500,
  },
  shape: {
    borderRadius: '11px',
  },
  overrides: {
    MuiInput: {
      input: {
        padding: '0.5rem 1rem',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid #878787`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#FFFFFF',
      },
    },
    MuiButton: {
      root: {
        minWidth: 108,
        height: 29,
        padding: '0 1rem',
        borderRadius: '5px',
      },
      outlined: {
        padding: '0 1rem',
      },
      text: {
        padding: '0 8px',
        whiteSpace: 'nowrap',
      },
      label: {
        whiteSpace: 'nowrap',
      },
    },
    MuiLink: {
      root: {
        color: '#fff',
      },
    },
    MuiPaper: {
      root: {
        border: 'solid 1px #7B7D81',
      },
      elevation1: {
        boxShadow: '4px 8px 20px 0 rgba(0, 0, 0, 0.26)',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 43,
      },
    },
    MuiTab: {
      root: {
        // width: 260,
        height: 43,
        minHeight: 43,
        padding: '0 24px 0 32px',
        borderRadius: '11px 11px 0 0',
        opacity: 1,
        background: navy.main,
        border: `1px solid #7B7D81`,
        letterSpacing: 1.95,
        whiteSpace: 'nowrap',
      },
      wrapper: {
        // fontSize: 20,
        // fontWeight: 600,
        color: '#fff', // white.main,
      },
      textColorInherit: {
        opacity: 1,
        '&:disabled': {
          opacity: 1,
          background: '#25364D',
        },
      },
    },
    // TABLE STYLES
    MuiTable: {
      root: {
        color: '#fff',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        padding: '2px 12px',
        fontWeight: 200,
        // fontSize: 18,
      },
      head: {
        // fontSize: 18,
        fontWeight: 600,
        padding: '12px 12px',
      },
    },
    MuiTablePagination: {
      root: {
        padding: 100,
      },
    },
    MuiToolbar: {
      root: {
        padding: 0,
      },
    },
  },
});

export default theme;
