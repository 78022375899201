import smartboxbasel from './smartboxbasel';
import slt from './slt';
import schlauebox from './schlauebox';
import vaultgroup from './vaultgroup';
import salubox from './salubox';
import wechip from './wechip';
import zwick from './zwick';

export default {
  smartboxbasel,
  slt,
  schlauebox,
  vaultgroup,
  salubox,
  wechip,
  zwick,
};
