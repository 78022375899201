import { css } from 'styled-components';
import '@fontsource/metropolis/500.css';

export default css`
  body {
    font-family: 'Metropolis', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    background: #4C4B47;
    font-size: 15px;
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 890px) {
    body {
      background-size: cover;
    }
  }

  /* override webkit autofill styling */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #2b3e5b inset;
    -webkit-text-fill-color: #fff;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #2b3e5b inset;
    -webkit-text-fill-color: #fff;
  }
`;
