import { Link, useMediaQuery } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import emailIcon from '../../../assets/images/mail-icon.svg';
import phoneIcon from '../../../assets/images/ic_phone.svg';
import infoIcon from '../../../assets/images/information-icon.svg';
import { version } from '../../../../package.json';
import { Container, PageContent } from './Content.styled';
import Survey from '../Survey';
import { BrandContext } from 'app/brandcontext/brandContext';

const Content = ({ children }) => {
  const isWideView = useMediaQuery('(min-width: 830px)');
  const { t } = useTranslation();

  const { brandName } = useContext(BrandContext);
  const brandLink = `mailto:support@${brandName}.ch`;

  const brandGuideLink = `https://guide.${brandName}.ch`;

  const linkStyle = {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#fff',
    // marginLeft: '1rem',
  };

  return (
    <Container>
      <PageContent isWideView={isWideView}>{children}</PageContent>
      <div className="support">
        <Survey />
        {brandName === 'smartboxbasel' || brandName === 'salubox' || brandName === 'wechip' ? (
          <>
            <span>
              {t`Support`} ({t`workdays`}: 8:00 - 12:00 & 13:30 - 17:00)
            </span>
            <Link href="tel:0617859085" className="contact">
              <img src={phoneIcon} alt={'Phone icon'} />
              061 785 90 85
            </Link>
            <Link href={brandLink} className="contact">
              <img src={emailIcon} alt={'Email icon'} />
              support@{brandName}.ch
            </Link>
            <Link
              href={brandGuideLink}
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={infoIcon}
                  alt="Information"
                  style={{ marginRight: '0.3rem' }}
                />
                {t`Help`}
              </span>
            </Link>
          </>
        ) : null}
      </div>
      <div className="support">
        <div className={brandName === 'salubox' ? 'saluboxVersion' : 'version'}>
          v{version}
        </div>
      </div>
    </Container>
  );
};

export default Content;
