import { css } from 'styled-components';
import 'typeface-rajdhani';

export default css`
  body {
    font-family: 'Rajdhani', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    background: linear-gradient(#01a79d, #28acde);
    font-size: 15px;
    width: 100%;
    margin: 0 auto;
  }

  /* override webkit autofill styling */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #2b3e5b inset;
    -webkit-text-fill-color: #fff;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #2b3e5b inset;
    -webkit-text-fill-color: #fff;
  }
`;
