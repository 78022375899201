import { BrandContext } from 'app/brandcontext/brandContext';
import Loader from 'components/shared/Loader';
import PropTypes from 'prop-types';
import React, {
  createContext,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import httpClient from 'utilities/httpClient';
import Content from './Content';
import Menu from './Menu';
import Topbar from './Topbar';
import UnverifiedUser from './UnverifiedUser';

export const AddressValidationContext = createContext();

const Layout = ({ children, page }) => {
  const [requiresValidation, setRequiresValidation] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState();
  const handleToggleDrawer = () => setIsDrawerOpen(prev => !prev);
  const currentUser = useSelector(state => state.auth.current);
  const { brandName } = useContext(BrandContext);

  // check if user has access requests that require physical address or mobile confirmation
  useEffect(() => {
    httpClient.get('/api/accessRequest/list').then(res => {
      const addressConfirmationRequests = res.data.filter(
        request =>
          (request.accessRequestType___type === 'ADDRESS_CONFIRMATION' ||
            request.accessRequestType___type === 'PHONE_CONFIRMATION') &&
          request.status === 'PENDING'
      );
      if (addressConfirmationRequests.length > 0) setRequiresValidation(true);
      // for SBB and salubox flow, address verification applies for all lockers
      if ((brandName === 'smartboxbasel' || brandName === 'salubox' || brandName === 'wechip') && !currentUser.addressConfirmed)
        setRequiresValidation(true);
    });
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <div style={{ margin: '3rem' }}>
            <Loader button />
          </div>
        }
      >
        {requiresValidation ? <UnverifiedUser /> : ''}
        <Topbar onToggleDrawer={handleToggleDrawer} />
        <Menu
          isDrawerOpen={isDrawerOpen}
          handleDrawerClose={handleToggleDrawer}
        />
      </Suspense>
      <AddressValidationContext.Provider value={{ setRequiresValidation }}>
        <Content page={page}>{children}</Content>
      </AddressValidationContext.Provider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  goBack: PropTypes.func,
};

export default Layout;
